import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import linkResolver from "../utils/linkResolver"

const BlogPost = ({ post, showLabel }) => (
  <>
    <Link className="blogPostLink" to={linkResolver(post)}>
      <div class="blogPost">
        <h2 class="title uppercut">{post.data.title.text}</h2>
        <span class="date uppercut">{post.data.date}</span>
        &nbsp;
        {showLabel && <span class="label">news</span>}
      </div>
    </Link>

    <p>{post.data.summary.text}</p>
  </>
)

BlogPost.propTypes = {
  post: PropTypes.shape,
  showLabel: PropTypes.bool,
}

BlogPost.defaultProps = {
  post: null,
  showLabel: false,
}

export default BlogPost
