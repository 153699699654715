import React from "react"
import { graphql } from "gatsby"

import BlogPost from "../components/blog_post"
import Layout from "../components/layout"
import SEO from "../components/seo"

function Blog({ data }) {
  return (
    <Layout>
      <SEO title="Blog" />
      {data.allPrismicPost.edges.map(({ node }, i) => (
        <div key={i} className="pure-u-1-1">
          <BlogPost post={node} />
        </div>
      ))}
    </Layout>
  );
};

export default Blog

export const pageQuery = graphql`
  query {
    allPrismicPost(sort: {fields: data___date, order: DESC}) {
      edges {
        node {
          id
          type
          uid
          data {
            date(formatString:"MMMM Do, YYYY")
            title {
              text
            }
            tags {
              tag
            }
            summary {
              text
            }
            content {
              html
            }
          }
        }
      }
    }
  }
`