const linkResolver = (doc) => {
  if (doc.type === 'post') {
    return '/blog/' + doc.uid
  }

  if (doc.type === 'app') {
    return '/apps/' + doc.uid
  }

  return `/${doc.uid}`;
}

module.exports = linkResolver
